import React from 'react';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';

const propTypes = {
  ...SectionTilesProps.types
}

const defaultProps = {
  ...SectionTilesProps.defaults
}

class News extends React.Component {

  render() {

    const {
      className,
      topOuterDivider,
      bottomOuterDivider,      
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      pushLeft,
      ...props
    } = this.props;

    const outerClasses = classNames(
      'news section',
      topOuterDivider && 'has-top-divider',
      bottomOuterDivider && 'has-bottom-divider',
      hasBgColor && 'has-bg-color',
      invertColor && 'invert-color',
      className
    );

    const innerClasses = classNames(
      'news-inner section-inner',
      topDivider && 'has-top-divider',
      bottomDivider && 'has-bottom-divider'
    );

    const tilesClasses = classNames(
      'tiles-wrap',
      pushLeft && 'push-left'
    );

    const sectionHeader = {
      title: 'Read more about SpreeSync',
    };

    return (
      <section
        {...props}
        className={outerClasses}
      >
        <div className="container">
          <div className={innerClasses}>
            <SectionHeader data={sectionHeader} className="center-content reveal-from-bottom" />
            <div className={tilesClasses}>

              <div className="tiles-item reveal-from-bottom">
                <div className="tiles-item-inner has-shadow">
                  <figure className="news-item-image m-0">
                    <Image
                      src={require('./../../assets/images/news-image-01.jpg')}
                      alt="News 01"
                      width={344}
                      height={185} />
                  </figure>
                  <div className="news-item-content">
                    <div className="news-item-body">
                      <h3 className="news-item-title h4 mt-0 mb-8">
                        <a href="https://medium.com/@rkdy49/harnessing-community-growth-and-partnerships-in-the-web3-ecosystem-65aac5758495" target='#'>
                        Harnessing Community Growth and Partnerships in the Web3 Ecosystem
                        </a>
                      </h3>
                      
                    </div>
                    <div className="news-item-more text-xs mb-8">
                      <a href="https://medium.com/@rkdy49/harnessing-community-growth-and-partnerships-in-the-web3-ecosystem-65aac5758495" target='#'>Read more</a>
                    </div>
                  </div>
                </div>
              </div>

              <div className="tiles-item reveal-from-bottom" data-reveal-delay="200">
                <div className="tiles-item-inner has-shadow">
                  <figure className="news-item-image m-0">
                    <Image
                      src={require('./../../assets/images/SpreeSyncDashboard.png')}
                      alt="News 02"
                      width={344}
                      height={194} />
                  </figure>
                  <div className="news-item-content">
                    <div className="news-item-body">
                      <h3 className="news-item-title h4 mt-0 mb-8">
                        <a href="https://medium.com/@rkdy49/why-spreesync-is-the-ultimate-tool-for-web3-community-growth-995fcd298fc2" target="#">Why SpreeSync is the Ultimate Tool for Web3 Community Growth</a>
                      </h3>
                      
                    </div>
                    <div className="news-item-more text-xs mb-8">
                      <a href="https://medium.com/@rkdy49/why-spreesync-is-the-ultimate-tool-for-web3-community-growth-995fcd298fc2" target="#">Read more</a>
                    </div>
                  </div>
                </div>
              </div>

              <div className="tiles-item reveal-from-bottom" data-reveal-delay="400">
                <div className="tiles-item-inner has-shadow">
                  <figure className="news-item-image m-0">
                    <Image
                      src={require('./../../assets/images/news-image-03.jpg')}
                      alt="News 03"
                      width={344}
                      height={194} />
                  </figure>
                  <div className="news-item-content">
                    <div className="news-item-body">
                      <h3 className="news-item-title h4 mt-0 mb-8">
                        <a href="https://medium.com/@rkdy49/embracing-the-future-how-spreesync-revolutionizes-community-engagement-in-web3-6adf442798e7" target="#">Embracing the Future: How SpreeSync Revolutionizes Community Engagement in Web3</a>
                      </h3>
                    </div>
                    <div className="news-item-more text-xs mb-8">
                      <a href="https://medium.com/@rkdy49/embracing-the-future-how-spreesync-revolutionizes-community-engagement-in-web3-6adf442798e7" target="#">Read more</a>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </section>
    );
  }
}

News.propTypes = propTypes;
News.defaultProps = defaultProps;

export default News;
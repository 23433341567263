import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { SectionProps } from "../../utils/SectionProps";
import Input from "../elements/Input";
import { db } from "../../utils/firebase";
import { doc, setDoc } from "firebase/firestore";
import "../../assets/scss/core/sections/_cta.css";

const propTypes = {
  ...SectionProps.types,
  split: PropTypes.bool,
};

const defaultProps = {
  ...SectionProps.defaults,
  split: false,
};

class Cta extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      ifSubmit: false,
    };
  }

  render() {
    const addEmailToWaitlist = async (email) => {
      try {
        // Use the 'doc' function to specify the document ID (email)
        const docRef = doc(db, "waitlist", email);

        // Use 'setDoc' to create the document with the specified ID
        await setDoc(docRef, {
          email: email,
          timestamp: new Date(),
        });

        console.log("Document written with ID: ", email);
      } catch (e) {
        console.error("Error adding document: ", e);
      }
    };
    const handleSubmit = async (e) => {
      e.preventDefault();
      await addEmailToWaitlist(this.state.email);
      this.setState({ email: "", ifSubmit: true }); // Clear the input field after submission
    };

    const {
      className,
      topOuterDivider,
      bottomOuterDivider,
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      split,
      ...props
    } = this.props;

    const outerClasses = classNames(
      "cta section center-content-mobile",
      topOuterDivider && "has-top-divider",
      bottomOuterDivider && "has-bottom-divider",
      hasBgColor && "has-bg-color",
      invertColor && "invert-color",
      className
    );

    const innerClasses = classNames(
      "cta-inner section-inner",
      topDivider && "has-top-divider",
      bottomDivider && "has-bottom-divider",
      split && "cta-split"
    );

    return (
      <section id="getEarlyAccess" {...props} className={outerClasses}>
        <div className="container" style={{ paddingTop: "20px" }}>
          {this.state.ifSubmit ? (
            <div
              className={innerClasses}
              style={{ display: "flex", flexDirection: "column" }}
            >
              <div className="cta-slogan">
                <h3 className="m-0" style={{fontFamily: "Inter"}}>Thanks for Signing Up!</h3>
              </div>
            </div>
          ) : (
            <div className={innerClasses}>
              <div className="cta-slogan">
                <h3 className="m-0" style={{fontFamily: "Inter"}}>Join Waitlist to Get Early Access!</h3>
              </div>
              <div className="cta-action">
                <form className="input-container" onSubmit={handleSubmit}>
                  <Input
                    id="newsletter"
                    type="email"
                    label="Subscribe"
                    labelHidden
                    hasIcon="right"
                    placeholder="Your email "
                    value={this.state.email}
                    onChange={(e) =>
                      this.setState({ email: e.target.value, ifSubmit: false })
                    }
                    required
                  ></Input>

                  <button type="submit">
                    <svg
                      width="16"
                      height="12"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M9 5H1c-.6 0-1 .4-1 1s.4 1 1 1h8v5l7-6-7-6v5z"
                        fill="#376DF9"
                      />
                    </svg>
                  </button>
                </form>
              </div>
            </div>
          )}
        </div>
      </section>
    );
  }
}

Cta.propTypes = propTypes;
Cta.defaultProps = defaultProps;

export default Cta;
